import React from 'react'
import $ from 'jquery'

import firebase from '../firebase'

import { selectedImageDivs } from './LoadAlbum.js'
import { showElement, hideElement } from './ImageHeader.js'
import { albumTitle, exportedComponentDidMount } from './LoadAlbum.js'

var firestore = firebase.firestore()
var storage = firebase.storage()

class DeleteImagesPopup extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <div className='delete-images-popup'>
        <p id='delete-images-label'></p>
        <button id='confirm'>
          <div className="sk-circle">
            <div className="sk-circle1 sk-child"></div>
            <div className="sk-circle2 sk-child"></div>
            <div className="sk-circle3 sk-child"></div>
            <div className="sk-circle4 sk-child"></div>
            <div className="sk-circle5 sk-child"></div>
            <div className="sk-circle6 sk-child"></div>
            <div className="sk-circle7 sk-child"></div>
            <div className="sk-circle8 sk-child"></div>
            <div className="sk-circle9 sk-child"></div>
            <div className="sk-circle10 sk-child"></div>
            <div className="sk-circle11 sk-child"></div>
            <div className="sk-circle12 sk-child"></div>
          </div>
          <p>Confirm</p>
        </button>
        <button id='cancel'>Cancel</button>
      </div>
    )
  }

  async componentDidMount() {
    deleteImagesPopupButtonListeners()
  }
}

const deleteImagesPopupButtonListeners = () => {
  $('.delete-images-popup #cancel').click(function() {
    hideElement('.delete-images-popup')
    $('.dimmer').css('display', 'none')
    $('body').css('overflow', 'auto')
  })

  $('.delete-images-popup #confirm').click(async function() {
    console.log(selectedImageDivs);
    $('.delete-images-popup .sk-circle').show()
    $('.delete-images-popup #confirm p').hide()
    var storageRef = storage.ref()
    for (var i = 0; i < selectedImageDivs.length; i++) {
      var albumQuery = await firestore.collection('Albums').where('album_title', '==', albumTitle).get()
      var doc_id = albumQuery.docs.map(doc => doc.id)[0]

      var imgQuery = await firestore.collection('Albums').doc(doc_id).collection('images').where('img_id', '==', selectedImageDivs[i]).get()
      var img_doc_data = imgQuery.docs.map(doc => doc.data())

      var img_name = img_doc_data[0].file_name

      await imgQuery.docs.map(doc => doc.ref.delete())
      var imageRef = storageRef.child('/' + albumTitle + '/' + img_name)

      await imageRef.delete()

    }
    exportedComponentDidMount()

    $('.delete-images-popup .sk-circle').hide()
    $('.delete-images-popup #confirm p').show()

    $('.delete-images-popup #confirm p').html('Finished')

    setTimeout(function() {
      hideElement('.delete-images-popup')
      $('.dimmer').hide()
    }, 1000)

    setTimeout(function() {
      $('.delete-images-popup #confirm p').html('Confirm')
    }, 2000)
  })
}

export default DeleteImagesPopup
