import React from 'react'
import $ from 'jquery'

import firebase from '../firebase'

import { selectedBackgroundImage, clearBackgroundImage, handleFiles, dragUploadListener, showElement, hideElement, toggleDimmer } from './ImageHeader.js'
import { albumTitle, exportedComponentDidMount } from './LoadAlbum.js'

var auth = firebase.auth()
var firestore = firebase.firestore()
var storage = firebase.storage()

class UploadAlbumImages extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <div className='upload-album-images-modal'>
        <div className='drag-drop-area'>
          <input type="file" accept="image/*" onChange={handleFiles(this.files)}></input>
          <p id='drag-drop-directions'>Drag and drop your photos</p>
        </div>

        <button id='confirm'>
          <div className="sk-circle">
            <div className="sk-circle1 sk-child"></div>
            <div className="sk-circle2 sk-child"></div>
            <div className="sk-circle3 sk-child"></div>
            <div className="sk-circle4 sk-child"></div>
            <div className="sk-circle5 sk-child"></div>
            <div className="sk-circle6 sk-child"></div>
            <div className="sk-circle7 sk-child"></div>
            <div className="sk-circle8 sk-child"></div>
            <div className="sk-circle9 sk-child"></div>
            <div className="sk-circle10 sk-child"></div>
            <div className="sk-circle11 sk-child"></div>
            <div className="sk-circle12 sk-child"></div>
          </div>
          <p>Confirm</p>
        </button>
        <button id='cancel'>Cancel</button>

        <p id='upload-album-images-error'>Please drag and drop some photo(s).</p>

        <div className='progress-bar'>
          <div className='loading-bar'>
            <p>0%</p>
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    buttonListeners()

    auth.onAuthStateChanged((user) => {
      if (user) {
        var dropArea = document.querySelector('.drag-drop-area')
        dragUploadListener(dropArea, '.upload-album-images-modal .drag-drop-area', '', '', true)
      } else {

      }
    })
  }
}

const buttonListeners = () => {
  $('.upload-album-images-modal #cancel').click(function() {
    toggleDimmer()
    hideElement('.upload-album-images-modal')
    clearBackgroundImage()
    $('#drag-drop-directions').html('Drag and drop your photos')
  })

  $('.upload-album-images-modal #confirm').click(async function() {
    // console.log(selectedBackgroundImage);
    if (!selectedBackgroundImage || selectedBackgroundImage.length == 0) {
      showElement('#upload-album-images-error')
      $('.upload-album-images-modal .drag-drop-area').css('border', '1px solid #db6861')

      setTimeout(function() {
        hideElement('#upload-album-images-error')
        $('.upload-album-images-modal .drag-drop-area').css('border', 'none')
      }, 5000)

    } else {
      $('.upload-album-images-modal #confirm').prop('disabled', true)
      $('.sk-circle').css('display', 'block')
      showElement('.progress-bar')

      var incrementer = 0
      var container_num = 0

      for (var i = 0; i < selectedBackgroundImage.length; i++) {

        if (selectedBackgroundImage[i].type.split('/')[1] == 'heic') {
          console.log('HEIC image detected ... ');
          continue
        } else {

          if (incrementer % 4 == 0 && incrementer != 0) {
            container_num += 1
          }

          incrementer += 1

          var loading_bar_percentage = ((i+1)/selectedBackgroundImage.length) * 100
          var storageRef = storage.ref()
          var imagesRef = storageRef.child('/' + albumTitle + '/' + selectedBackgroundImage[i].name)

          await imagesRef.put(selectedBackgroundImage[i])

          var temp_url = await storageRef.child('/' + albumTitle + '/' + selectedBackgroundImage[i].name).getDownloadURL()

          var temp_img_obj = {
            file_name: selectedBackgroundImage[i].name,
            img_url: temp_url,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            img_id: generateImageID(),
            priority_container: container_num
          }

          var albumQuery = await firestore.collection('Albums').where('album_title', '==', albumTitle).get()
          var doc_id = albumQuery.docs.map(doc => doc.id)[0]

          await firestore.collection('Albums').doc(doc_id).collection('images').add(temp_img_obj)

          console.log(`Added image ${selectedBackgroundImage[i].name}`);

          if (i == selectedBackgroundImage.length-1) {
            changePercentageLabel(((i-1)/selectedBackgroundImage.length) * 100, 100)
            $('.loading-bar').css('width', '99%')
          } else {
            if (i == 0) {
              changePercentageLabel((0/selectedBackgroundImage.length) * 100, loading_bar_percentage)
            } else {
              changePercentageLabel(((i-1)/selectedBackgroundImage.length) * 100, loading_bar_percentage)
            }
            $('.loading-bar').css('width', loading_bar_percentage + '%')
          }
        }
      }

      $('.sk-circle').css('display', 'none')
      $('#confirm p').html('Finished uploading')
      $('.upload-album-images-modal #confirm').prop('disabled', false)

      exportedComponentDidMount()

      setTimeout(function() {
        toggleDimmer()
        hideElement('.upload-album-images-modal')
      }, 1000)

      setTimeout(function() {
        $('#confirm p').html('Confirm')
        $('#drag-drop-directions').html('Drag and drop your photos')
        clearBackgroundImage()
        hideElement('.progress-bar')
        $('.loading-bar').css('width', '10%')
        $('.loading-bar p').html('0%')
      }, 3000)

    }
  })
}

const changePercentageLabel = (initial, final) => {
  $('.loading-bar p').each(function () {
      $(this).prop('Counter', initial).animate({
          Counter: final
      }, {
          duration: 600,
          easing: 'linear',
          step: function (now) {
              $(this).text(Math.ceil(now) + '%');
          }
      });
  });
}

const generateImageID = () => {
  var chars = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
  var image_id = ""

  for (var i = 0; i < chars.length; i++) {
    var randomNum = getRandomInt(chars.length)
    image_id += chars[randomNum]
  }

  return image_id
}

const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
}

export default UploadAlbumImages
