import React from 'react'
import $ from 'jquery'

import firebase from '../firebase'

import { activateNavbarEditMode, deactivateNavbarEditMode } from './Navbar.js'
import { exportedComponentDidMount, clearSelectedImages, selectedImageDivs, swappedImages, clearSwappedImages, albumPathname } from './LoadAlbum.js'
import { showElement, hideElement } from './ImageHeader.js'
import { resetEditMode } from './AdminTools.js'

var firestore = firebase.firestore()

class ConfirmSwapChanges extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <div className='confirm-swap-changes-popup'>
        <p id='swap-changes-label'></p>
        <button id='confirm'>Save</button>
        <button id='cancel'>Continue without saving</button>
      </div>
    )
  }

  async componentDidMount() {
    confirmSwapChangesButtonListeners()
  }
}

const confirmSwapChangesButtonListeners = () => {
  $('.confirm-swap-changes-popup #confirm').click(async function() {
    console.log(swappedImages);
    for (var i = 0; i < swappedImages.length; i++) {
      var albumQuery = await firestore.collection('Albums').where('album_pathname', '==', albumPathname).get()
      var doc_id = albumQuery.docs.map(doc => doc.id)[0]

      var albumImagesQuery = await firestore.collection('Albums').doc(doc_id).collection('images').where('img_id', '==', swappedImages[i].id).get()
      var album_image_doc_id = albumImagesQuery.docs.map(doc => doc.id)[0]

      // console.log(album_image_doc_id);
      await firestore.collection('Albums').doc(doc_id).collection('images').doc(album_image_doc_id).update({
        priority_num: swappedImages[i].priority_num,
        priority_container: swappedImages[i].priority_container
      })
    }

    $('.dimmer').hide()
    hideElement('.confirm-swap-changes-popup')
    clearSwappedImages()
    reloadAlbumPage()

  })

  $('.confirm-swap-changes-popup #cancel').click(function() {
    $('.dimmer').hide()
    hideElement('.confirm-swap-changes-popup')
    clearSwappedImages()

    reloadAlbumPage()
  })
}

const reloadAlbumPage = () => {
  resetEditMode()
  deactivateNavbarEditMode()
  showElement('#edit-icon')
  hideElement('#exit-edit-icon')
  for (var i = 0; i < selectedImageDivs.length; i++) {
    $('#' + selectedImageDivs[i]).find('svg').hide()
    $('#' + selectedImageDivs[i]).find('#pre-selection').show()
  }
  clearSelectedImages()
  hideElement('.delete-selected-images')
  hideElement('.delete-album')
  $('.image-elem #delete-selection').css('display', 'none')

  exportedComponentDidMount()
}

export default ConfirmSwapChanges
