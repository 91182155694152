import React from 'react'
import $ from 'jquery'

import firebase from '../firebase'

import { handleFiles, dragUploadListener } from './ImageHeader.js'

var auth = firebase.auth()
var firestore = firebase.firestore()
var storageRef = firebase.storage().ref()

var mobile = window.matchMedia("(max-width: 700px)")
var tablet = window.matchMedia("(min-width: 760px) and (max-width: 1000px)")

class About extends React.Component {

  constructor() {
    super()
  }

  render() {
    return (
      <div className='about-container'>
        <div className='about-head'>
          <span id='left'></span>
          <p id='about-container-header'>About Me</p>
          <span id='right'></span>
        </div>

        <div className='info-container'>
          <div className='about-image'>
            <img src='#'></img>
            <input type="file" accept="image/*" onChange={handleFiles(this.files)}></input>
          </div>

          <div className='about-me-info-container'>
            <p id='about-me-info'></p>

            <textarea id='about-me-info-input'></textarea>
          </div>

          <div className='about-edit-icon-container'>
            <svg id='about-edit-icon' xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1.8em" fill="black" className="bi bi-pencil" viewBox="0 0 16 16">
              <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
            </svg>

            <svg id='about-save-edit-icon' xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="black" className="bi bi-check2" viewBox="0 0 16 16">
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
            </svg>
          </div>
        </div>

      </div>
    )
  }

  async componentDidMount() {
    aboutClickListener()
    aboutEditIconListeners()

    if (mobile.matches) {
      $('.about-head').css('width', '50%')
      $('.about-head').css('left', '7.5%')
      $('.info-container').css('width', '95%')
      $('.about-edit-icon-container').remove()
    }

    if (tablet.matches) {
      $('.about-head').css('width', '25%')
      $('.about-head').css('left', '7.5%')
      $('.info-container').css('width', '95%')
      $('.about-edit-icon-container').remove()
    }

    var about_image_url = await storageRef.child('about_image.jpg').getDownloadURL()
    $('.about-image img').attr('src', about_image_url)
    $('.about-image img').css('display', 'block')

    var aboutQuery = await firestore.collection('StaticInfo').doc('About').get()
    var about_doc_data = aboutQuery.data()

    $('#about-me-info').html(about_doc_data.text)

    auth.onAuthStateChanged((user) => {
      if (user) {
        var dropArea = document.querySelector('.about-image')
        dragUploadListener(dropArea, '.about-image img', '.change-about-popup #selected-image', '.change-about-popup')
        $('.about-edit-icon-container').css('display', 'flex')
      } else {
        $('.about-change-popup').remove()
        $('.about-edit-icon-container').remove()
      }
    })
  }

}

const aboutClickListener = () => {
  $(".about-head").click(function() {
    var aboutContainer = document.querySelector('.about-container')

    aboutContainer.scrollIntoView({behavior: "smooth", block: "end"});
  })
}

const aboutEditIconListeners = () => {
  $('#about-edit-icon').click(function() {
    $('#about-edit-icon').hide()
    $('#about-save-edit-icon').show()

    var aboutMeInfoVal = $('#about-me-info').html()
    $('#about-me-info-input').val(aboutMeInfoVal)
    $('#about-me-info').hide()
    $('#about-me-info-input').show()
  })

  $('#about-save-edit-icon').click(async function() {
    $('#about-edit-icon').show()
    $('#about-save-edit-icon').hide()

    var new_about_info = $('#about-me-info-input').val()

    await firestore.collection('StaticInfo').doc('About').set({
      text: new_about_info
    })

    $('#about-me-info').html(new_about_info)

    $('#about-me-info').show()
    $('#about-me-info-input').hide()
  })
}

export default About
