import React from 'react'
import $ from 'jquery'

import firebase from '../firebase'

import { toggleDimmer, showElement, hideElement, handleFiles, dragUploadListener } from './ImageHeader.js'
import { addFooter } from '../screens/Home.js'

var auth = firebase.auth()
var firestore = firebase.firestore()

var mobile = window.matchMedia("(max-width: 700px)")
var tablet = window.matchMedia("(min-width: 760px) and (max-width: 1000px)")

class Albums extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <div className='albums-container'>
        <div className='albums-head'>
          <span id='left'></span>
          <p id='albums-container-header'>My Albums</p>
          <span id='right'></span>
        </div>

        <button className='add-album-button'>Add Album</button>

        <div className='albums'>
          <p id='no-albums-label'>Oops! No albums have been added yet.</p>
        </div>
      </div>
    )
  }

  async componentDidMount() {
    albumsClickListener()
    addAlbumButtonClickListener()

    if (mobile.matches) {
      $('.albums-head').css('width', '60%')
      $('.albums-head').css('left', '7.5%')
      $('.albums-head').css('border-bottom', '1px solid white')
      $('#no-albums-label').css('line-height', 'unset')
      $('#no-albums-label').css('position', 'absolute')
      $('#no-albums-label').css('top', '250px')
      $('#no-albums-label').css('padding', '25px')
      $('#no-albums-label').css('font-size', '35px')

      $('.albums').css('margin-bottom', '40%')
      $('.add-album-button').remove()
    }

    if (tablet.matches) {
      $('.albums-head').css('width', '25%')
      $('.albums-head').css('left', '7.5%')
      $('.albums-head').css('border-bottom', '1px solid white')
      $('.add-album-button').remove()
    }

    var albumsRef = await firestore.collection('Albums').orderBy('timestamp').get()
    var albums = albumsRef.docs.map(doc => doc.data())

    await loadAlbumsCollection(albums)

    auth.onAuthStateChanged((user) => {
      if (user) {
        var dropArea = document.querySelector('.album-card-image-container')
        dragUploadListener(dropArea, '.album-card-image-container', '.album-card-image-container img', '')
        $('.add-album-button').show()
      } else {
        $('.add-album-modal').remove()
        $('.add-album-button').remove()
      }
    })
  }
}

const albumsClickListener = () => {
  $(".albums-head").click(function() {
    var albumsContainer = document.querySelector('.albums-container')

    albumsContainer.scrollIntoView({behavior: "smooth", block: "end"})
  })
}

const loadAlbumsCollection = async (objs) => {

  if (objs.length == 0) {
    showElement('#no-albums-label')
  } else {
    for (var i = 0; i < objs.length; i++) {
      loadAlbum(objs[i])
    }

  }

  setTimeout(function() {
    addFooter('.albums-container')
  }, 150)
}

export const loadAlbum = (obj) => {
  let albums = document.querySelector('.albums')

  var albumDiv = document.createElement('div')
  albumDiv.className = 'album'
  albumDiv.id = obj.album_title

  albumDiv.innerHTML = `
    <div className='album-image-container' style='width: 95%; height: 80%; margin: 0 auto; margin-top: 2.5%;'>
      <img src=${obj.album_img_url} style='width: 100%; height: 100%; object-fit: cover; border-radius: 15px;' />
    </div>
    <p style='font-size: 30px; text-align: center; line-height: 70px;'>${obj.album_title}</p>
  `

  albumDiv.style.width = '18%'

  if (mobile.matches) {
    albumDiv.style.width = '90%'
  }

  if (tablet.matches) {
    albumDiv.style.width = '45%'
  }

  albumDiv.style.height = '400px'
  // albumDiv.style.border = '1px solid #efefef'
  albumDiv.style.backgroundColor = 'white'
  albumDiv.style.borderRadius = '15px'
  albumDiv.style.display = 'inline-block'
  albumDiv.style.textAlign = 'center'
  albumDiv.style.marginLeft = '1.5%'
  albumDiv.style.marginRight = '1.5%'
  albumDiv.style.marginTop = '2.5%'
  albumDiv.style.overflow = 'hidden'
  albumDiv.style.transition = '.3s'

  hideElement('#no-albums-label')
  $('#no-albums-label').css('display', 'none')

  setTimeout(function() {
    albums.prepend(albumDiv)
    albumsDivHoverListener()
    albumsDivClickListener()
  }, 100)
}

const albumsDivHoverListener = () => {
  $(document).unbind('mouseenter')
  $(document).unbind('mouseleave')
  $(document).on('mouseenter', '.album', function() {
    $(this).css('box-shadow', '0px 0px 35px #b9b9b9')
    $(this).css('cursor', 'pointer')
    $(this).find('p').css('color', '#679edf')
  }).on('mouseleave', '.album', function() {
    $(this).css('box-shadow', 'unset')
    $(this).css('cursor', 'initial')
    $(this).find('p').css('color', 'black')
  })

  if (mobile.matches) {
    $(document).on('touchstart', '.album', function() {
      $(this).css('box-shadow', '0px 0px 35px #b9b9b9')
      $(this).css('cursor', 'pointer')
      $(this).find('p').css('color', '#679edf')
    }).on('touchend', '.album', function() {
      $(this).css('box-shadow', 'unset')
      $(this).css('cursor', 'initial')
      $(this).find('p').css('color', 'black')
    })
  }
}

const albumsDivClickListener = () => {
  $('.album').unbind('click')
  $('.album').click(function() {
    var albumTitle = $(this).find('p').html()
    var albumPath = albumTitle.toLowerCase().replace(/ /g, '-')

    window.location = '/album/' + albumPath
  })
}

const addAlbumButtonClickListener = () => {
  $('.add-album-button').click(function() {
    $('.dimmer').css('display', 'block')
    $('body').css('overflow', 'hidden')
    showElement('.add-album-modal')

    setTimeout(function() {
      $('.album-card').css('transform', 'rotateY(360deg)')
    }, 0)

    setTimeout(function() {
      showElement('.album-card')
    }, 250)

    setTimeout(function() {
      $('.album-card').css('box-shadow', '0px 0px 35px #b9b9b9')
    }, 250)

  })
}

export default Albums
