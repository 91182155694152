import React from 'react'
import $ from "jquery";

import firebase from '../firebase'
import { showErrorMessage } from './LoginErrorMessage.js'

var firestore = firebase.firestore()
var auth = firebase.auth()

var mobile = window.matchMedia("(max-width: 700px)")

class LoginComponent extends React.Component {

  constructor() {
    super()
  }

  render() {
    return (
      <div className="login-container">
        <p>Log In</p>
        <input className="login-input" id="username" placeholder="Username"></input>
        <input className="login-input" id="password" placeholder="Password" type="password"></input>

        <button className="login-button">Log In</button>
      </div>
    )
  }

  componentDidMount() {

    auth.onAuthStateChanged((user) => {
      if (user) {
          $('.login-container p').remove()
          $('.login-input').remove()
          $('.login-button').remove()

          var loginContainer = document.querySelector('.login-container')
          var alreadyLoggedIn = document.createElement('p')
          alreadyLoggedIn.innerHTML = `You are already logged in!`

          alreadyLoggedIn.style.lineHeight = '400px'
          alreadyLoggedIn.style.top = 'unset'
          alreadyLoggedIn.style.left = 'unset'
          alreadyLoggedIn.style.transform = 'unset'
          alreadyLoggedIn.style.position = 'relative'
          alreadyLoggedIn.style.textAlign = 'center'

          loginContainer.append(alreadyLoggedIn)
      }
    })

    loginMountedFunc()
    loginButtonClickListener()
    mobileCSS()
  }
}

const loginMountedFunc = () => {
  setTimeout(function() {
    $('body').css('background', '#f97777')
    $('.login-container').css('visibility', 'visible')
  }, 300)

  setTimeout(function() {
    $('.login-container').css('box-shadow', '0px 0px 25px #9a4a4a')
  }, 500)
}

const loginButtonClickListener = () => {
  $('.login-button').click(async function() {
    loginUser()
  })

  $(document).keyup(async function(e) {
    if (e.which === 13) {
      loginUser()
    }
  })

  async function loginUser() {
    var username = $('.login-input#username').val()
    var password = $('.login-input#password').val()

    var userDoc = await checkUserCredentials(username)

    if (userDoc.length == 1) {
      var email = userDoc[0].data().email
      auth.signInWithEmailAndPassword(email, password).then((user) => {
        window.location.href = '/'
      }).catch((e) => {
        showErrorMessage('Incorrect Credentials')
      })
    } else {
      showErrorMessage('Incorrect Credentials')
    }
  }
}

const checkUserCredentials = async (username) => {
  var user_query = firestore.collection('User').where('username', '==', username)
  try {
    var user_snap = await user_query.get()
    return user_snap.docs
  } catch (e) {
    console.log('Error getting user snapshot');
  }
}

const mobileCSS = () => {
  if (mobile.matches) {
    $('.login-container').css('width', '90%')
    $('.login-container').css('margin-top', '35%')
    $('.login-error-message').css('width', '90%')
  }
}

export default LoginComponent
