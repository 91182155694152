import React from 'react'
import $ from 'jquery'

import firebase from '../firebase'

import { selectedBackgroundImage, handleFiles, dragUploadListener, showElement, hideElement } from './ImageHeader.js'
import { loadAlbum } from './Albums.js'

var storage = firebase.storage()
var firestore = firebase.firestore()

class AddAlbum extends React.Component {

  constructor() {
    super()
  }

  render() {
    return (
      <div className='add-album-modal'>
        <div className='album-card'>
          <div className='album-card-image-container'>
            <img src='#'></img>
            <input type="file" accept="image/*" onChange={handleFiles(this.files)}></input>
            <p id='drag-drop-directions'>Drag and drop your cover picture</p>
          </div>
          <p id='add-album-title'></p>
        </div>

        <div className='album-info'>
          <input id='add-album-title-input' placeholder='Name your album'></input>
          <p id='album-title-taken-error'>This album name already exists!</p>
          <button id='cancel-add-album'>Cancel</button>
          <button id='confirm-add-album'>
            <div className="sk-circle">
              <div className="sk-circle1 sk-child"></div>
              <div className="sk-circle2 sk-child"></div>
              <div className="sk-circle3 sk-child"></div>
              <div className="sk-circle4 sk-child"></div>
              <div className="sk-circle5 sk-child"></div>
              <div className="sk-circle6 sk-child"></div>
              <div className="sk-circle7 sk-child"></div>
              <div className="sk-circle8 sk-child"></div>
              <div className="sk-circle9 sk-child"></div>
              <div className="sk-circle10 sk-child"></div>
              <div className="sk-circle11 sk-child"></div>
              <div className="sk-circle12 sk-child"></div>
            </div>
            <p>Confirm</p>
          </button>
        </div>

        <div className='album-modal-error'>
          <p>Please fill out the highlighted fields.</p>
        </div>
      </div>
    )
  }

  componentDidMount() {
    addAlbumTitleListeners()
    addAlbumButtonClickListener()
  }
}

const addAlbumTitleListeners = () => {
  $(document).on('keyup', '#add-album-title-input', function() {
    var val = $('#add-album-title-input').val()

    $('#add-album-title').html(val)
  })
}

const checkErrors = async () => {
  var imageSrc = $('.album-card-image-container img').attr('src')
  var titleValue = $('#add-album-title-input').val()
  var errorValues = [false, false, false]

  console.log('getting...');
  var albums = await firestore.collection('Albums').where('album_title', '==', titleValue).get()
  console.log('got');
  var album = albums.docs.map(doc => doc.data())
  console.log('done');

  if (album.length != 0) {
    errorValues[2] = true
    $('#add-album-title-input').css('border', '1px solid #db6861')
    showElement('#album-title-taken-error')
  }

  if (imageSrc === '#') {
    errorValues[0] = true
    $('.album-card-image-container').css('border', '1px solid #db6861')
  }

  if (titleValue === '' || titleValue.trim() === '') {
    errorValues[1] = true
    $('#add-album-title-input').css('border', '1px solid #db6861')
  }

  if (errorValues.includes(true)) {
    $('.album-modal-error').css('top', '-65px')
    setTimeout(function() {
      $('.album-modal-error').css('visibility', 'visible')
      $('.album-modal-error').css('opacity', '1')
    }, 100)
    console.log('here1');

    return true
  } else {
    return false
  }
}

const addAlbumButtonClickListener = () => {
  $('#confirm-add-album').click(async function() {
    var hasErrors = await checkErrors()

    if (!hasErrors) {
      var albumTitle = $('#add-album-title').html()

      var storageRef = storage.ref()
      var imageRef = storageRef.child(albumTitle + '/album_title_image.jpg')

      $('#confirm-add-album p').css('display', 'none')
      $('.sk-circle').css('display', 'block')

      await imageRef.put(selectedBackgroundImage)
      var new_url = await storageRef.child(albumTitle + '/album_title_image.jpg').getDownloadURL()

      var album_pathname = albumTitle.toLowerCase().replace(/ /g, '-')

      var albumObj = {
        album_title: albumTitle,
        album_img_url: new_url,
        album_pathname: album_pathname,
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
      }

      var firestoreRef = firestore.collection('Albums')
      await firestoreRef.add(albumObj)

      $('#confirm-add-album p').css('display', 'block')
      $('.sk-circle').css('display', 'none')
      $('#confirm-add-album p').html('Added')

      loadAlbum(albumObj)

      closeAndResetAddAlbumModal()

      $('#confirm-add-album p').html('Confirm')

      console.log("Sucessfully added object ...");
    } else {
      console.log('Not good');

      setTimeout(function() {
        $('#add-album-title-input').css('border', 'none')
        $('.album-card-image-container').css('border', 'none')

        $('.album-modal-error').css('top', '0px')
        setTimeout(function() {
          $('.album-modal-error').css('visibility', 'hidden')
          $('.album-modal-error').css('opacity', '0')
          hideElement('#album-title-taken-error')
        }, 10)
      }, 5000)
    }
  })

  $('#cancel-add-album').click(function() {
    closeAndResetAddAlbumModal()
  })

  const closeAndResetAddAlbumModal = () => {
    $('.dimmer').css('display', 'none')
    $('body').css('overflow', 'auto')
    hideElement('.add-album-modal')
    hideElement('.album-card-image-container img')

    $('#add-album-title-input').val('')
    $('#add-album-title').html('')
    $('.album-card-image-container img').attr('src', '#')

    setTimeout(function() {
      $('.album-card').css('transform', 'rotateY(180deg)')
    }, 0)

    setTimeout(function() {
      hideElement('.album-card')
    }, 250)

    setTimeout(function() {
      $('.album-card').css('box-shadow', 'unset')
      $('#drag-drop-directions').css('display', 'block')
    }, 250)
  }

}

export default AddAlbum
