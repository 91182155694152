import React from 'react'
import $ from 'jquery'

import firebase from '../firebase'

import { showElement, hideElement, toggleDimmer } from '../components/ImageHeader.js'
import { activateNavbarEditMode, deactivateNavbarEditMode } from './Navbar.js'
import { clearSelectedImages, selectedImageDivs, swappedImages } from './LoadAlbum.js'

var auth = firebase.auth()
var mobile = window.matchMedia("(max-width: 700px)")
var tablet = window.matchMedia("(min-width: 760px) and (max-width: 1000px)")
export var editMode = false

class AdminTools extends React.Component {
  constructor () {
    super()
  }

  render () {
    return (
      <div className='admin-tools'>
        <svg id='tools-icon' xmlns="http://www.w3.org/2000/svg" width="1.9em" height="1.9em" fill="white" className="bi bi-tools" viewBox="0 0 16 16">
          <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z"/>
        </svg>

        <div className='edit-icon-container'>
          <svg id='edit-icon' xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1.8em" fill="white" className="bi bi-pencil" viewBox="0 0 16 16">
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
          </svg>

          <svg id='exit-edit-icon' xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1.8em" fill="white" className="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
          </svg>
        </div>


        <svg id='upload-icon' xmlns="http://www.w3.org/2000/svg" width="1.9em" height="1.9em" fill="white" className="bi bi-upload" viewBox="0 0 16 16">
          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
          <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
        </svg>
      </div>
    )
  }

  componentDidMount() {
    mobileCSS()
    tabletCSS()
    auth.onAuthStateChanged((user) => {
      if (user) {
        adminToolsListeners()
        $('.admin-tools').show()
      } else {
        $('.admin-tools').remove()
      }
    })
  }
}

const adminToolsListeners = () => {
  $(document).on('mouseenter', '.admin-tools #tools-icon', function() {
    // $('.admin-tools').css('cursor', 'pointer')
    $('.admin-tools').css('box-shadow', '0px 0px 10px #4f7bac')

    setTimeout(function() {
      $('.admin-tools').css('border-radius', '25px')
    }, 50)

    setTimeout(function() {
      $('.admin-tools').css('height', '170px')

      if (editMode) {
        showElement('.admin-tools #exit-edit-icon')
      } else {
        showElement('.admin-tools #edit-icon')
      }

      showElement('.admin-tools #upload-icon')
    }, 100)
  }).on('mouseleave', '.admin-tools', function() {
    $('.admin-tools').css('cursor', 'initial')
    $('.admin-tools').css('box-shadow', 'unset')
    if (editMode) {
      hideElement('.admin-tools #exit-edit-icon')
    } else {
      hideElement('.admin-tools #edit-icon')
    }
    hideElement('.admin-tools #upload-icon')

    setTimeout(function() {
      $('.admin-tools').css('height', '50px')
    }, 50)

    setTimeout(function() {
      $('.admin-tools').css('border-radius', '25px')
    }, 100)
  })

  $('.admin-tools #upload-icon').click(function() {
    toggleDimmer()
    showElement('.upload-album-images-modal')
  })

  $('.admin-tools #edit-icon').click(function() {
    editMode = true
    activateNavbarEditMode()
    hideElement('#edit-icon')
    showElement('#exit-edit-icon')
    $('.image-elem #delete-selection').css('display', 'flex')
    showElement('.delete-album')
  })

  $('.admin-tools #exit-edit-icon').click(function() {
    if (swappedImages.length > 0) {
      $('#swap-changes-label').html(`Are you sure you would like to save <span style='font-weight: bold;'>${swappedImages.length}</span> image swaps?`)
      $('.dimmer').show()
      showElement('.confirm-swap-changes-popup')
    } else {
      editMode = false
      deactivateNavbarEditMode()
      showElement('#edit-icon')
      hideElement('#exit-edit-icon')
      for (var i = 0; i < selectedImageDivs.length; i++) {
        $('#' + selectedImageDivs[i]).find('svg').hide()
        $('#' + selectedImageDivs[i]).find('#pre-selection').show()
      }
      clearSelectedImages()
      hideElement('.delete-selected-images')
      hideElement('.delete-album')
      $('.image-elem #delete-selection').css('display', 'none')
    }
  })
}

export const resetEditMode = () => {
  editMode = false
}

const mobileCSS = () => {
  if (mobile.matches) {
    $('.admin-tools').css('display', 'none')
  }
}

const tabletCSS = () => {
  if (tablet.matches) {
    $('.admin-tools').css('display', 'none')
  }
}

export default AdminTools
