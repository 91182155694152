
import {Route, Routes, Link} from 'react-router-dom'

import Home from './screens/Home.js'
import Login from './screens/Login.js'
import Album from './screens/Album.js'

import firebase from './firebase'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/album/*' element={<Album />} />
      </Routes>
    </div>
  );
}

export default App;
