import React from 'react'
import $ from 'jquery'

import { showElement, hideElement } from '../components/ImageHeader.js'

var props_path
var button_msg

var mobile = window.matchMedia("(max-width: 700px)")

class BackButton extends React.Component {
  constructor() {
    super()
  }

  render() {
    props_path = this.props.path
    button_msg = this.props.button_msg
    return (
      <div className='back-button'>
        <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" fill="#679fdf" className="bi bi-arrow-left" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
        </svg>
        <p>{button_msg}</p>
      </div>
    )
  }

  componentDidMount() {
    backToHomeListener(props_path)
    mobileCSS()
  }
}

const backToHomeListener = (props_path) => {
  $('.back-button').click(function() {
    window.location.href = props_path
  })

  $(document).on('mouseenter', '.back-button', function() {
    $('.back-button').css('cursor', 'pointer')
    $('.back-button').css('box-shadow', '0px 0px 10px #4f7bac')

    setTimeout(function() {
      $('.back-button').css('border-radius', '25px')
    }, 50)

    setTimeout(function() {
      $('.back-button').css('width', '250px')
      showElement('.back-button p')
    }, 100)
  }).on('mouseleave', '.back-button', function() {
    $('.back-button').css('cursor', 'initial')
    $('.back-button').css('box-shadow', 'unset')
    hideElement('.back-button p')

    setTimeout(function() {
      $('.back-button').css('width', '50px')
    }, 50)

    setTimeout(function() {
      $('.back-button').css('border-radius', '25px')
    }, 100)
  })
}

const mobileCSS = () => {
  if (mobile.matches) {
    $('.back-button').css('display', 'none')
  }
}

export default BackButton
