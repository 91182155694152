import React from 'react'
import $ from 'jquery'

class LoginErrorMessage extends React.Component {

  constructor() {
    super()
  }

  render() {
    return (
      <div className='login-error-message'>
        <p>This is the default error message ..</p>
      </div>
    )
  }

  componentDidMount() {

  }
}

export function showErrorMessage(msg) {
  $('.login-error-message p').html(msg)

  $('.login-error-message').css('visibility', 'visible')
  $('.login-error-message').css('margin-top', '20px')

  setTimeout(function() {
    $('.login-error-message').css('margin-top', '-60px')
    $('.login-error-message').css('visibility', 'hidden')
  }, 2000)
}

export default LoginErrorMessage
