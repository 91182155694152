// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAJGGOQmapy1Bu706NTdHOdCqcXH8WyAFo",
  authDomain: "steph-9831f.firebaseapp.com",
  projectId: "steph-9831f",
  storageBucket: "steph-9831f.appspot.com",
  messagingSenderId: "410421003800",
  appId: "1:410421003800:web:38fd01271141aff930256d",
  measurementId: "G-QTRFX71H9Q"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export default firebase;
