import React from 'react'
import $ from 'jquery'

import firebase from '../firebase'

var auth = firebase.auth()
var storage = firebase.storage()
var storageRef = storage.ref()

var mobile = window.matchMedia("(max-width: 700px)")
var tablet = window.matchMedia("(min-width: 760px) and (max-width: 1000px)")

export var selectedBackgroundImage

class ImageHeader extends React.Component {

  constructor() {
    super()
  }

  render() {
    return (
      <div className='image-header-container'>
        <input type="file" accept="image/*" onChange={handleFiles(this.files)}></input>
        <button className='view-more-button'>
          Learn more
        </button>
      </div>
    )
  }

  async componentDidMount() {
    viewMoreButtonClickListener()
    scrollListener()

    if (mobile.matches) {
      $('.view-more-button').css('width', '80%')
      $('.view-more-button').css('height', '60px')
      $('.view-more-button').css('font-size', '22.5px')

      $('.image-header-container').css('background-attachment', 'unset')
    }

    if (tablet.matches) {
      $('.view-more-button').css('width', '50%')
      $('.view-more-button').css('height', '60px')
      $('.view-more-button').css('font-size', '22.5px')
    }

    var background_image_url = await storageRef.child('background_image.jpg').getDownloadURL()
    $('.image-header-container').css('background-image', 'url(' + background_image_url + ')')

    auth.onAuthStateChanged((user) => {
      if (user) {
        var dropArea = document.querySelector('.image-header-container')
        dragUploadListener(dropArea, '.image-header-container', '.change-background-popup #selected-image', '.change-background-popup')
      } else {
        $('.change-background-popup').remove()
      }
    })
  }
}

const viewMoreButtonClickListener = () => {
  var aboutContainer = document.querySelector('.about-container')
  var top = aboutContainer.top + window.scrollY;

  $(".view-more-button").click(function() {
    aboutContainer.scrollIntoView({behavior: "smooth", block: "end"});
  })
}

const scrollListener = () => {
  $(window).scroll(function (event) {
    var scroll = $(window).scrollTop();
    var scrollInVh = scroll/(window.innerHeight/100)

    if (scrollInVh >= 205) {
      $('.navbar').css('background-color', '#679edf')
      $('#navbar-header').css('color', 'white')
      $('.navbar-chin').css('background-color', '#679edf')

      $(".navbar-chin #left").css('box-shadow', '0 -25px 0 0 #679edf')
      $(".navbar-chin #right").css('box-shadow', '0 -25px 0 0 #679edf')
    }

    if (scrollInVh < 205) {
      $('.navbar').css('background-color', 'white')
      $('#navbar-header').css('color', '#679edf')
      $('.navbar-chin').css('background-color', 'white')

      $(".navbar-chin #left").css('box-shadow', '0 -25px 0 0 white')
      $(".navbar-chin #right").css('box-shadow', '0 -25px 0 0 white')
    }
  })
}

export const dragUploadListener = (dropArea, highlightElem, selectedImage, popUpElem, multiple=false) => {

  ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
    dropArea.addEventListener(eventName, preventDefaults, false)
  })

  function preventDefaults (e) {
    e.preventDefault()
    e.stopPropagation()
  }

  ;['dragenter', 'dragover'].forEach(eventName => {
    dropArea.addEventListener(eventName, highlight, false)
  })

  ;['dragleave', 'drop'].forEach(eventName => {
    dropArea.addEventListener(eventName, unhighlight, false)
  })

  function highlight(e) {
    $(highlightElem).css('opacity', '.6')
  }

  function unhighlight(e) {
    $(highlightElem).css('opacity', '1')
  }

  dropArea.addEventListener('drop', handleDrop, false)
  dropArea.selectedImage = selectedImage
  dropArea.multiple = multiple

  if (popUpElem != '') { dropArea.popUpElem = popUpElem }
}

const handleDrop = (e) => {
  let dt = e.dataTransfer
  let files = dt.files

  let tempImageElem = e.currentTarget.selectedImage
  let popUpElem = e.currentTarget.popUpElem
  let multipleFiles = e.currentTarget.multiple

  handleFiles(files, tempImageElem, popUpElem, multipleFiles)
}

export const handleFiles = (files, tempImageElem, popUpElem, multipleFiles=false) => {
  if (files) {
    var reader = new FileReader()

    if (!multipleFiles) {
      selectedBackgroundImage = files[0]
    } else {
      selectedBackgroundImage = files
    }

    reader.onload = function(e) {
      $(tempImageElem).attr('src', e.target.result)
      if (popUpElem == null) {
        showElement(tempImageElem)
        if (multipleFiles) {
          // $('#drag-drop-directions').html(files.length + ' files selected.')
          document.querySelector('#drag-drop-directions').innerHTML = (files.length == 1) ? '1 file selected.' : files.length + ' files selected.'
        } else {
          $('#drag-drop-directions').css('display', 'none')
        }
      }
    }

      reader.readAsDataURL(files[0])

    if (popUpElem != null) { toggleDimmer() }
    if (popUpElem != null) { showElement(popUpElem) }
  }
}


export const clearBackgroundImage = () => {
  selectedBackgroundImage = null
}

var dimmerOn = false
export const toggleDimmer = () => {
  if (dimmerOn) {
    $('.dimmer').css('display', 'none')
    $('body').css('overflow', 'auto')
    dimmerOn = false
  } else {
    $('.dimmer').css('display', 'block')
    $('body').css('overflow', 'hidden')
    dimmerOn = true
  }
}

export const showElement = (elem) => {
  $(elem).css('visibility', 'visible')
  $(elem).css('opacity', '1')
}

export const hideElement = (elem) => {
  $(elem).css('visibility', 'hidden')
  $(elem).css('opacity', '0')
}

export default ImageHeader
