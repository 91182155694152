import React from 'react'
import $ from 'jquery'

import firebase from '../firebase'
import { selectedBackgroundImage, toggleDimmer, showElement, hideElement } from './ImageHeader.js'

var storage = firebase.storage()

class ChangeBackground extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <div className='change-background-popup'>
        <div className='selected-image-container'>
          <img id='selected-image' src='#'></img>
        </div>
        <p id='main-label'>Are you sure you would like to change the background image?</p>
        <button id='cancel'>Cancel</button>
        <button id='confirm'>
          <div className="sk-circle">
            <div className="sk-circle1 sk-child"></div>
            <div className="sk-circle2 sk-child"></div>
            <div className="sk-circle3 sk-child"></div>
            <div className="sk-circle4 sk-child"></div>
            <div className="sk-circle5 sk-child"></div>
            <div className="sk-circle6 sk-child"></div>
            <div className="sk-circle7 sk-child"></div>
            <div className="sk-circle8 sk-child"></div>
            <div className="sk-circle9 sk-child"></div>
            <div className="sk-circle10 sk-child"></div>
            <div className="sk-circle11 sk-child"></div>
            <div className="sk-circle12 sk-child"></div>
          </div>
          <p>OK</p>
        </button>
      </div>
    )
  }

  componentDidMount() {
    changeBackgroundButtonClickListeners()
  }
}

const changeBackgroundButtonClickListeners = () => {
  $('.change-background-popup #cancel').click(function() {
    toggleDimmer()
    hideElement('.change-background-popup')
  })

  $('.change-background-popup #confirm').click(async function() {
    $('.change-background-popup #confirm').prop('disabled', true)
    var storageRef = storage.ref()
    var imageRef = storageRef.child('background_image.jpg')

    $('.change-background-popup #confirm p').css('display', 'none')
    $('.sk-circle').css('display', 'block')

    await imageRef.put(selectedBackgroundImage)

    $('.change-background-popup #confirm p').css('display', 'block')
    $('.sk-circle').css('display', 'none')
    $('.change-background-popup #confirm p').html('Uploaded')

    var new_url = await storageRef.child('background_image.jpg').getDownloadURL()
    $('.image-header-container').css('background-image', 'url(' + new_url + ')')

    setTimeout(function() {
      toggleDimmer()
      hideElement('.change-background-popup')
    }, 500)

    setTimeout(function() {
      $('.change-background-popup #confirm p').html('OK')
      $('.change-background-popup #confirm').prop('disabled', false)

    }, 700)
  })
}

export default ChangeBackground
