import React from 'react'
import $ from 'jquery'

import firebase from '../firebase'

import { showElement, hideElement } from './ImageHeader.js'

class ImageView extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <div className='image-view'>
        <svg xmlns="http://www.w3.org/2000/svg" width="2.2em" height="2.2em" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        <div className='image-view-container'>
          <img id='image-view-img'></img>
        </div>
      </div>
    )
  }

  componentDidMount() {
    addImageViewListeners()
  }
}

const addImageViewListeners = () => {
  $('.image-view svg').click(function() {
    $('.dimmer').hide()
    $('body').css('overflow', 'auto')
    hideElement('.image-view')

    setTimeout(function() {
      $('#image-view-img').attr('src', '#')

    }, 500)
  })
}

export const showImageView = (url, orientation) => {
  if (orientation === 'vertical') {
    $('.image-view').css('width', '30%')
  } else {
    $('.image-view').css('width', '85%')

  }

  setTimeout(function() {
    $('.dimmer').show()
    $('body').css('overflow', 'hidden')
    showElement('.image-view')

    $('#image-view-img').attr('src', url)
  }, 200)
}

export default ImageView
