import React from 'react'

import $ from "jquery";
import firebase from '../firebase'

var mobile = window.matchMedia("(max-width: 700px)")
var tablet = window.matchMedia("(min-width: 760px) and (max-width: 1000px)")

var auth = firebase.auth()

class Navbar extends React.Component {

  constructor() {
    super()
  }

  render() {
    return (
      <div className="navbar" style={{width: '100%', top: 0, height: '20px', position: 'fixed', transition: '.2s'}}>
        <div className='navbar-chin'>
          <span id="left"></span>
          <p id="navbar-header">Stephanie Ripp</p>
          <span id="right"></span>
        </div>

        <button className='logout-button'>
          <svg id='logout-icon' xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="white" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
            <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
          </svg>
        </button>
      </div>
    )
  }

  componentDidMount() {

    auth.onAuthStateChanged((user) => {
      if (user) {
        $('.logout-button').show()
      }
    })

    navbarMountedFunc()
    navbarClickListener()
    mobileCSS()
    tabletCSS()
    logoutButtonClickListener()

  }

}

export const activateNavbarEditMode = () => {
  $('.navbar').css('background-color', '#ea7f1b')
  $('.navbar-chin').css('background-color', '#ea7f1b')
  $(".navbar-chin #left").css('box-shadow', '0 -25px 0 0 #ea7f1b')
  $(".navbar-chin #right").css('box-shadow', '0 -25px 0 0 #ea7f1b')
  $('#navbar-header').html('Edit Mode')
}

export const deactivateNavbarEditMode = () => {
  $('.navbar').css('background-color', '#679edf')
  $('.navbar-chin').css('background-color', '#679edf')
  $(".navbar-chin #left").css('box-shadow', '0 -25px 0 0 #679edf')
  $(".navbar-chin #right").css('box-shadow', '0 -25px 0 0 #679edf')
  $('#navbar-header').html('Stephanie Ripp')
}

const logoutButtonClickListener = () => {
  $('.logout-button').click(async function() {
    await auth.signOut()
    window.location.reload()
  })
}

const navbarMountedFunc = () => {
  setTimeout(function() {
    $('#navbar-header').css('visibility', 'visible')
  }, 200)

  if (window.location.pathname == '/') {
    // $('.navbar').css('background-color', 'white')
    $('#navbar-header').css('color', '#679edf')
  }

  if (window.location.pathname.includes('/album/')) {
    $('.navbar').css('background-color', '#679edf')
    $('#navbar-header').css('color', 'white')
    $('.navbar-chin').css('background-color', '#679edf')

    $(".navbar-chin #left").css('box-shadow', '0 -25px 0 0 #679edf')
    $(".navbar-chin #right").css('box-shadow', '0 -25px 0 0 #679edf')
  }
}

const navbarClickListener = () => {
  $('.navbar-chin').click(function() {

    if (window.location.pathname.includes('/album/')) {
      window.location = '/'
    } else {
      var imageHeaderContainer = document.querySelector('.image-header-container')
      imageHeaderContainer.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  })
}

const mobileCSS = () => {
  if (mobile.matches) {
    $('.navbar-chin').css('width', '90%')
    // $('#navbar-header').css('font-family', '"Cormorant Garamond", serif')
  }
}

const tabletCSS = () => {
  if (tablet.matches) {
    $('.navbar-chin').css('width', '35%')
  }
}

export default Navbar
