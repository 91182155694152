import React from 'react'
import $ from 'jquery'

import firebase from '../firebase'
import { showElement, hideElement } from './ImageHeader.js'

import { editMode } from './AdminTools.js'
import { addFooter } from '../screens/Home.js'
import { showImageView } from './ImageView.js'

var auth = firebase.auth()
var firestore = firebase.firestore()

var currImageContainer
var invertImageContainer
export var swappedImages = []

export var albumTitle
export var selectedImageDivs = []

var mobile = window.matchMedia("(max-width: 700px)")
var tablet = window.matchMedia("(min-width: 760px) and (max-width: 1000px)")

export const albumPathname = window.location.pathname.split('/')[2]

class LoadAlbum extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <div className='album-page'>
        <p id='no-album-images'>Oops! There are no picutres in this album yet.</p>
        <div className='album-images'>
        </div>

        <button className='delete-selected-images'>Delete images</button>
        <button className='delete-album'>Delete Album</button>
      </div>
    )
  }

  async componentDidMount() {
    exportedComponentDidMount()
  }
}

export const exportedComponentDidMount = async () => {
  await loadAlbumPage()

  tabletCSS()

  $('.album-images-container').remove()
  invertImageContainer = true

  swappedImages = []
  selectedImageDivs = []

  var albumQuery = await firestore.collection('Albums').where('album_pathname', '==', albumPathname).get()
  var doc_id = albumQuery.docs.map(doc => doc.id)[0]

  var albumImagesQuery = await firestore.collection('Albums').doc(doc_id).collection('images').orderBy('priority_container', 'asc').get()
  var albumImagesObjs = albumImagesQuery.docs.map(doc => doc.data())

  if (albumImagesObjs.length == 0) {
    showElement('#no-album-images')
    hideElement('.delete-selected-images')

    auth.onAuthStateChanged((user) => {
      if (user) {
        addDeleteAlbumListeners()
      }
    })
  } else {
    hideElement('#no-album-images')
    console.log(albumImagesObjs);
    loadImages(albumImagesObjs)

    auth.onAuthStateChanged((user) => {
      if (user) {
        addImageElemListeners()
        addDeleteAlbumListeners()
        addDeleteSelectedImagesListeners()
      } else {
        $('.delete-selected-images').remove()
      }

      if (!mobile.matches) {
        var w
        var h
        var parentWidth
        var percent
        $(document).on('mouseenter', '.image-elem', function() {
          parentWidth = $(this).offsetParent().width();
          w = $(this).css('width').split('px')[0]
          percent = (parseInt(w)/parentWidth)*100

          h = $(this).css('height').split('px')[0]

          $(this).css('width', percent + .5 + '%')
          $(this).css('height', (parseInt(h) + 5).toString() + 'px')

          $(this).css('cursor', 'pointer')
          $(this).css('box-shadow', '0px 5px 10px #9d9b9f')

        }).on('mouseleave', '.image-elem', function() {
          $(this).css('width', percent + '%')
          $(this).css('height', h + 'px')

          $(this).css('cursor', 'initial')
          $(this).css('box-shadow', 'unset')

        })

        $('.image-elem').click(function() {
          if (!editMode) {
            var img_src = $(this).find('img').attr('src')
            var orientation = 'horizontal'

            var parentWidth = $(this).offsetParent().width();
            var w = $(this).css('width').split('px')[0]
            var percent = (parseInt(w)/parentWidth)*100

            console.log(percent);
            if (Math.floor(percent) == 30) {
              orientation = 'vertical'
            }

            showImageView(img_src, orientation)
          }
        })
      }
    })
  }

  setTimeout(function() {
    addFooter('.album-page')
    $('.footer').css('position', 'relative')
    mobileCSS()
  }, 150)
}

const loadAlbumPage = async () => {

  var albumQuery = await firestore.collection('Albums').where('album_pathname', '==', albumPathname).get()
  var album = albumQuery.docs.map(doc => doc.data())

  albumTitle = album[0].album_title

}

const toggleInvert = (invert) => {
  if (invert) {
    invertImageContainer = false
  } else {
    invertImageContainer = true
  }
}

const loadImages = (img_objs) => {
  var objs = restructImagesArr([...img_objs])
  objs = objs.filter(elem => elem != null)
  console.log(objs);

  for (var i = 0; i < objs.length; i++) {

    var containerSetNumber = (i % 4) + 1

    if (i % 4 == 0) {
      toggleInvert(invertImageContainer)

      createNewImageDiv(i/4)
      currImageContainer = document.querySelector('#c' + i/4 + '.album-images-container')

      if (mobile.matches) {
        addImagetoContainerMobile(objs[i], currImageContainer)
      } else {
        addImagetoContainer(objs[i], currImageContainer, containerSetNumber, invertImageContainer)
      }
    } else {
      if (mobile.matches) {
        addImagetoContainerMobile(objs[i], currImageContainer)
      } else {
        addImagetoContainer(objs[i], currImageContainer, containerSetNumber, invertImageContainer)
      }
    }
  }
}

const createNewImageDiv = (containerNumber) => {
  const albumImages = document.querySelector('.album-images')

  var albumImagesDiv = document.createElement('div')
  albumImagesDiv.className = 'album-images-container'
  albumImagesDiv.id = 'c' + containerNumber

  albumImagesDiv.style.position = 'relative'
  albumImagesDiv.style.margin = '0 auto'

  if (mobile.matches) {
    albumImagesDiv.style.width = '100%'
    albumImagesDiv.style.marginTop = (containerNumber == 0) ? '80px' : '15px'
  } else {
    albumImagesDiv.style.width = '95%'
    albumImagesDiv.style.height = '600px'
    albumImagesDiv.style.marginTop = (containerNumber == 0) ? '125px' : '15px'
  }

  albumImages.append(albumImagesDiv)
}

const addImagetoContainer = (obj, container, containerSetNumber, invertImageContainer) => {
  const albumImages = document.querySelector('.album-images')

  var imageElem = document.createElement('div')
  imageElem.className = 'image-elem'
  imageElem.id = obj.img_id

  imageElem.innerHTML = `
    <div id='delete-selection' style='display: flex; justify-content: center; align-items: center; width: 50px; height: 50px; position: absolute; top: 10px; right: 10px; border-radius: 50%; background-color: white; transition: .2s; display: none;'>
      <svg style='display: none;' xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" fill="#679fdf" class="bi bi-check-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
      </svg>
      <div id='pre-selection' style='width: 2.5em; height: 2.5em; border-radius: 50%; border: 1px solid #679fdf; transition: .2s;'>
      </div>
    </div>
    <img src='${obj.img_url}' style='width: 100%; height: 100%; object-fit: cover; border-radius: 5px; visibility: hidden; opacity: 0;' draggable='true'></img>
  `

  switch (containerSetNumber) {
    case (1):
      if (invertImageContainer) {
        imageElem.style.right = '5.5%'
      } else {
        imageElem.style.left = '5.5%'
      }
      imageElem.style.width = '30%'
      imageElem.style.height = '585px'
      imageElem.style.position = 'absolute'
      imageElem.style.top = '1.5%'
      break
    case (2):
      if (invertImageContainer) {
        imageElem.style.right = '37.5%'
      } else {
        imageElem.style.left = '37.5%'
      }
      imageElem.style.width = '27.5%'
      imageElem.style.height = '275px'
      imageElem.style.position = 'absolute'
      imageElem.style.top = '1.5%'
      break
    case (3):
      if (invertImageContainer) {
        imageElem.style.right = '67%'
      } else {
        imageElem.style.left = '67%'
      }
      imageElem.style.width = '27.5%'
      imageElem.style.height = '275px'
      imageElem.style.position = 'absolute'
      imageElem.style.top = '1.5%'
      break
    case (4):
      if (invertImageContainer) {
        imageElem.style.right = '37.5%'
      } else {
        imageElem.style.left = '37.5%'
      }
      imageElem.style.width = '57%'
      imageElem.style.height = '275px'
      imageElem.style.position = 'absolute'
      imageElem.style.top = '53%'
      break
    default:
      console.log('Error with container set number...');
  }

  imageElem.style.backgroundColor = '#efefef'
  imageElem.style.borderRadius = '5px'
  imageElem.style.overflow = 'hidden'
  imageElem.style.transition = '.2s'
  imageElem.style.visibility = 'hidden'
  imageElem.style.opacity = 0

  container.append(imageElem)

  dragImageSwapListener(document.getElementById(obj.img_id), document.getElementById(obj.img_id))

  if (editMode) {
    $('#' + obj.img_id + ' #delete-selection').css('display', 'flex')
  }

  setTimeout(function() {
    showElement('#' + obj.img_id)
  }, 200)

  setTimeout(function() {
    showElement('#' + obj.img_id + ' img')
  }, 1000)
}

const addImageElemListeners = () => {
  $(document).on('mouseenter', '.image-elem #delete-selection', function() {
    $(this).css('box-shadow', '0px 0px 10px')
    $(this).css('cursor', 'pointer')
    $(this).find('#pre-selection').css('background-color', '#679fdf')
  }).on('mouseleave', '.image-elem #delete-selection', function() {
    $(this).css('box-shadow', 'unset')
    $(this).css('cursor', 'initial')
    $(this).find('#pre-selection').css('background-color', 'white')
  })

  $('.image-elem #delete-selection').click(function() {
    var id = $(this).parent().attr('id')
    toggleImageSelected(id)
  })
}

const toggleImageSelected = (id) => {
  if ($('#' + id).find('svg').css('display') === 'block') {
    $('#' + id).find('svg').hide()
    $('#' + id).find('#pre-selection').show()
    removeImageSelected(id)
  } else {
    $('#' + id).find('svg').show()
    $('#' + id).find('#pre-selection').hide()
    selectedImageDivs.push(id)

    console.log(selectedImageDivs);
    if (selectedImageDivs.length == 1) { showElement('.delete-selected-images') }
  }
}

const removeImageSelected = (id) => {
  var indexOf = selectedImageDivs.indexOf(id)
  selectedImageDivs.splice(indexOf, 1)
  if (selectedImageDivs.length == 0) { hideElement('.delete-selected-images') }
}

export const clearSelectedImages = () => {
  selectedImageDivs = []
}

const addImagetoContainerMobile = (obj, container) => {
  const albumImages = document.querySelector('.album-images')

  var imageElem = document.createElement('div')
  imageElem.className = 'image-elem'
  imageElem.id = obj.img_id

  imageElem.innerHTML = `
    <img src='${obj.img_url}' style='width: 100%; height: 100%; object-fit: cover; border-radius: 5px; visibility: hidden; opacity: 0;' draggable='true'></img>
  `

  imageElem.style.width = '100%'
  imageElem.style.height = '475px'
  imageElem.style.display = 'block'
  imageElem.style.marginTop = '15px'

  imageElem.style.backgroundColor = '#efefef'
  imageElem.style.borderRadius = '5px'

  container.append(imageElem)

  setTimeout(function() {
    showElement('#' + obj.img_id)
  }, 200)

  setTimeout(function() {
    showElement('#' + obj.img_id + ' img')
  }, 1000)
}

const addDeleteSelectedImagesListeners = () => {
  $('.delete-selected-images').click(function() {
    $('.dimmer').show()
    $('.delete-images-popup #delete-images-label').html(`Are you sure you want to delete <span style='font-weight: bold; color: #679fdf;'>${selectedImageDivs.length}</span> images?`)
    showElement('.delete-images-popup')
  })
}

const addDeleteAlbumListeners = () => {
  $('.delete-album').click(function() {
    $('#delete-album-label').html(`Are you sure you want to delete the album <span style='font-weight: bold; color: #679fdf;'>${albumTitle}</span>?`)
    $('.dimmer').show()
    showElement('.delete-album-popup')
  })
}

var initialImageForSwap
var finalImageForSwap

const dragImageSwapListener = (dropArea, highlightElem) => {

  ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
    dropArea.addEventListener(eventName, preventDefaults, false)
  })

  function preventDefaults (e) {
    e.preventDefault()
    e.stopPropagation()
  }

  ;['dragenter', 'dragover'].forEach(eventName => {
    dropArea.addEventListener(eventName, highlight, false)
  })

  ;['dragstart'].forEach(eventName => {
    dropArea.addEventListener(eventName, detectImageDrag, false)
  })

  ;['dragleave', 'drop'].forEach(eventName => {
    dropArea.addEventListener(eventName, unhighlight, false)
  })

  function highlight(e) {
    if (editMode) { $(highlightElem).css('opacity', '.6') }
  }

  function unhighlight(e) {
    if (editMode) { $(highlightElem).css('opacity', '1') }
  }

  function detectImageDrag(e) {
    if (editMode) { initialImageForSwap = e.path[1].id }
  }

  dropArea.addEventListener('drop', handleDrop, false)
}

const handleDrop = (e) => {
  let dt = e.dataTransfer
  let files = dt.files

  // handleImageSwap(files)
  if (editMode) {
    finalImageForSwap = e.path[1].id

    var initial_parent_id = $('#' + initialImageForSwap).parent().attr('id')
    var final_parent_id = $('#' + finalImageForSwap).parent().attr('id')
    var initial_nodes = Array.from(document.querySelector('#' + initial_parent_id).childNodes).map(elem => elem.id)
    var final_nodes = Array.from(document.querySelector('#' + final_parent_id).childNodes).map(elem => elem.id)

    var initialImageSetNumber = initial_nodes.indexOf(initialImageForSwap) + 1
    var finalImageSetNumber = final_nodes.indexOf(finalImageForSwap) + 1

    // var initialImageContainerNumber =

    console.log('Initial Image ID : ', initialImageForSwap, ' Initial Set # : ', initialImageSetNumber);
    console.log('Final Image ID : ', finalImageForSwap, ' Initial Set # : ', finalImageSetNumber)

    if (initialImageSetNumber === finalImageSetNumber && initialImageForSwap === finalImageForSwap) {
      // do nothing
    } else {
      var initial_swap_obj = { id: initialImageForSwap, priority_num: finalImageSetNumber, priority_container: parseInt(final_parent_id.split('c')[1]) }
      var final_swap_obj = { id: finalImageForSwap, priority_num: initialImageSetNumber, priority_container: parseInt(initial_parent_id.split('c')[1]) }

      // swappedImages.push(initial_swap_obj)
      // swappedImages.push(final_swap_obj)

      addToSwappedImagesArray(initial_swap_obj)
      addToSwappedImagesArray(final_swap_obj)

      var initial_img_src = $('#' + initialImageForSwap + ' img').prop('src')
      var final_img_src = $('#' + finalImageForSwap + ' img').prop('src')

      var initial_img_parent = $('#' + initialImageForSwap)
      var final_img_parent = $('#' + finalImageForSwap)

      $('#' + finalImageForSwap + ' img').prop('src', initial_img_src)
      $('#' + initialImageForSwap + ' img').prop('src', final_img_src)

      initial_img_parent.attr('id', finalImageForSwap)
      final_img_parent.attr('id', initialImageForSwap)

    }

    console.log(swappedImages);

  }
}

const addToSwappedImagesArray = (obj) => {
  if (swappedImages.some(elem => elem.id === obj.id)) {
    var index = swappedImages.map(elem => elem.id).indexOf(obj.id)
    swappedImages[index].priority_num = obj.priority_num
    swappedImages[index].priority_container = obj.priority_container
  } else {
    swappedImages.push(obj)
  }
}

export const clearSwappedImages = () => {
  swappedImages = []
}

const restructImagesArr = (copy_arr) => {

  var arr = [...copy_arr]
  arr.sort((a, b) => a.priority_num - b.priority_num)
  arr.sort((a, b) => a.priority_container - b.priority_container)

  var incrementer = 0
  for (var i = 0; i < arr.length; i++) {
    if (!arr[i]) {
      continue
    }

    incrementer = (i % 4 == 0 && i != 0) ? incrementer += 4 : incrementer

    if (arr[i].priority_num) {
      var temp_index = (arr[i].priority_num-1) + incrementer
      var temp_elem = arr[temp_index]

      arr[temp_index] = arr[i]
      arr[i] = temp_elem

    } else {

      var new_arr = arr.slice(incrementer, incrementer+4).filter(elem => elem !== undefined)
      var possible_index = new_arr.findIndex(({priority_num}) => priority_num == i+1)

      if (possible_index != -1) {
          var temp_elem = arr[possible_index]

          arr[possible_index] = arr[i]
          arr[i] = temp_elem
      } else {
          continue
      }
    }
  }

  return arr
}

const getRandomInt = () => {
  return Math.floor(Math.random() * 3);
}

const mobileCSS = () => {
  if (mobile.matches) {
    $('.album-images').css('margin-bottom', '25%')
    $('.footer').css('bottom', 'unset')
  }
}

const tabletCSS = () => {
  if (tablet.matches) {
    $('.album-images').css('margin-top', '20%')
    $('.album-images').css('width', '100%')
  }
}

export default LoadAlbum
