import React from 'react'

import Navbar from '../components/Navbar.js'
import LoadAlbum from '../components/LoadAlbum.js'
import BackButton from '../components/BackButton.js'
import AdminTools from '../components/AdminTools.js'
import UploadAlbumImages from '../components/UploadAlbumImages.js'
import DeleteImagesPopup from '../components/DeleteImagesPopup.js'
import ConfirmSwapChanges from '../components/ConfirmSwapChanges.js'
import DeleteAlbumPopup from '../components/DeleteAlbumPopup.js'
import ImageView from '../components/ImageView.js'

function Album(props) {
  return (
    <div>
      <div className='dimmer'></div>
      <Navbar />
      <UploadAlbumImages />
      <DeleteImagesPopup />
      <DeleteAlbumPopup />
      <ConfirmSwapChanges />
      <BackButton path='/' button_msg='Back to home page'/>
      <AdminTools />
      <LoadAlbum />
      <ImageView />
    </div>
  )
}

export default Album
