import React from 'react'

import Navbar from '../components/Navbar.js'
import LoginComponent from '../components/LoginComponent.js'
import LoginErrorMessage from '../components/LoginErrorMessage.js'

function Login(props) {
  return (
    <div>
      <Navbar />
      <LoginComponent />
      <LoginErrorMessage />
    </div>
  )
}

export default Login;
