import React from 'react'
import $ from 'jquery'

import firebase from '../firebase'
import { showElement, hideElement } from './ImageHeader.js'
import { albumPathname, albumTitle } from './LoadAlbum.js'

var firestore = firebase.firestore()
var storage = firebase.storage()

class DeleteAlbumPopup extends React.Component {
  constructor() {
      super()
  }

  render() {
    return (
      <div className='delete-album-popup'>
        <p id='delete-album-label'></p>
        <button id='confirm'>
          <div className="sk-circle">
            <div className="sk-circle1 sk-child"></div>
            <div className="sk-circle2 sk-child"></div>
            <div className="sk-circle3 sk-child"></div>
            <div className="sk-circle4 sk-child"></div>
            <div className="sk-circle5 sk-child"></div>
            <div className="sk-circle6 sk-child"></div>
            <div className="sk-circle7 sk-child"></div>
            <div className="sk-circle8 sk-child"></div>
            <div className="sk-circle9 sk-child"></div>
            <div className="sk-circle10 sk-child"></div>
            <div className="sk-circle11 sk-child"></div>
            <div className="sk-circle12 sk-child"></div>
          </div>
          <p>Delete</p>
        </button>
        <button id='cancel'>Cancel</button>
      </div>
    )
  }

  componentDidMount() {
    deleteAlbumPopupListeners()
  }
}

const deleteAlbumPopupListeners = () => {
  $('.delete-album-popup #confirm').click(async function() {
    $('.delete-album-popup #confirm').prop('disabled', true)
    $('.delete-album-popup #cancel').prop('disabled', true)
    $('.delete-album-popup .sk-circle').show()
    $('.delete-album-popup #confirm p').hide()

    var albumQuery = await firestore.collection('Albums').where('album_pathname', '==', albumPathname).get()
    var album_doc_id = albumQuery.docs.map((doc) => doc.id)

    var albumImagesQuery = await firestore.collection('Albums').doc(album_doc_id[0]).collection('images').get()
    var album_images_filenames = await albumImagesQuery.docs.map((doc) => doc.data().file_name)

    await albumImagesQuery.docs.map((doc) => doc.ref.delete())

    await firestore.collection('Albums').doc(album_doc_id[0]).delete()

    for (var i = 0; i < album_images_filenames.length; i++) {
      await storage.ref().child('/' + albumTitle + '/' + album_images_filenames[i]).delete()
    }

    $('.delete-album-popup .sk-circle').hide()
    $('.delete-album-popup #confirm p').html('Deleted')
    $('.delete-album-popup #confirm p').show()

    setTimeout(function() {
      window.location.href = '/'
    }, 1000)
  })

  $('.delete-album-popup #cancel').click(function() {
    $('.dimmer').hide()
    hideElement('.delete-album-popup')
  })
}

export default DeleteAlbumPopup
